import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { auth, db } from './firebase';
import VendorList from './VendorList';
import SearchBar from './SearchBar';
import SignIn from './SignIn';
import './index.css';
import logo from './stripestreetlogo_black.png'; 

const categories = [
  'Furniture', 'Lighting', 'Flooring', 'Wall Art', 'Window Treatments', 'Rugs',
  'Bedding', 'Kitchenware', 'Outdoor Furniture', 'Appliances', 'Storage Solutions',
  'Accessories', 'Paint', 'Upholstery', 'Fixtures', 'Décor', 'Smart Home Technology',
  'Custom Cabinetry','Wallpaper', 'Fabric', 'Leather', 'Plants', 'Handymen','Hardware', 'Cleaning', 'Tailors'
];

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [newVendor, setNewVendor] = useState({
    name: '',
    email: '',
    userId: '',
    password: '',
    accountNumber: '',
    discount: '',
    contact: '',
    notes: '',
    categories: [],
    url: '',
    address: '',
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchVendors = async () => {
        const vendorCollection = collection(db, 'vendors');
        const vendorSnapshot = await getDocs(vendorCollection);
        const vendorList = vendorSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setVendors(vendorList);
      };

      fetchVendors();
    }
  }, [isAuthenticated]);

  const addVendor = async () => {
    if (newVendor.name) {
      const vendorRef = await addDoc(collection(db, 'vendors'), newVendor);
      setVendors([...vendors, { id: vendorRef.id, ...newVendor }]);
      setNewVendor({
        name: '',
        email: '',
        userId: '',
        password: '',
        accountNumber: '',
        discount: '',
        contact: '',
        notes: '',
        categories: [],
        url: '',
        address:''
      });
      setShowAddForm(false);
    }
  };

  const updateVendor = async (updatedVendor) => {
    const vendorRef = doc(db, 'vendors', updatedVendor.id);
    await updateDoc(vendorRef, updatedVendor);
    setVendors(vendors.map(vendor => vendor.id === updatedVendor.id ? updatedVendor : vendor));
  };

  const deleteVendor = async (id) => {
    const vendorRef = doc(db, 'vendors', id);
    await deleteDoc(vendorRef);
    setVendors(vendors.filter(vendor => vendor.id !== id));
  };

  const searchVendors = (query) => {
    setSearchQuery(query);
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    const updatedCategories = checked
      ? [...selectedCategories, value]
      : selectedCategories.filter(category => category !== value);
    setSelectedCategories(updatedCategories);
  };

  const filteredVendors = vendors
    .filter(vendor => vendor.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .filter(vendor => selectedCategories.length === 0 || vendor.categories.some(category => selectedCategories.includes(category)))
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleNewVendorChange = (e) => {
    const { name, value } = e.target;
    setNewVendor({
      ...newVendor,
      [name]: value,
    });
  };

  const handleNewVendorCategoryChange = (e) => {
    const { value, checked } = e.target;
    const updatedCategories = checked
      ? [...newVendor.categories, value]
      : newVendor.categories.filter(category => category !== value);
    setNewVendor({ ...newVendor, categories: updatedCategories });
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <>
          <header className="App-header">
            <img src={logo} alt="Logo" className="logo" />
            <h1>Vendor Management</h1>
          </header>
          <SearchBar onSearch={searchVendors} />
          <button className="filters-btn" onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </button>
          <button className="add-vendor-btn" onClick={() => setShowAddForm(!showAddForm)}>
            {showAddForm ? 'Cancel' : 'Add Vendor'}
          </button>
          {showFilters && !showAddForm && (
            <div className="category-filters">
              {categories.map(category => (
                <div
                  key={category}
                  className={`filter-button ${selectedCategories.includes(category) ? 'active' : ''}`}
                  onClick={() => handleCategoryChange({ target: { value: category, checked: !selectedCategories.includes(category) } })}
                >
                  {category}
                </div>
              ))}
            </div>
          )}
          {!showAddForm && (
            <VendorList vendors={filteredVendors} onEdit={updateVendor} onDelete={deleteVendor} />
          )}
          {showAddForm && (
            <div className="add-vendor-form">
              <h2>Add New Vendor</h2>
              <input type="text" name="name" placeholder="Vendor Name" value={newVendor.name} onChange={handleNewVendorChange} required />
              <input type="email" name="email" placeholder="Email" value={newVendor.email} onChange={handleNewVendorChange} />
              <input type="text" name="userId" placeholder="User ID" value={newVendor.userId} onChange={handleNewVendorChange} />
              <input type="text" name="password" placeholder="Password" value={newVendor.password} onChange={handleNewVendorChange} />
              <input type="text" name="accountNumber" placeholder="Account Number" value={newVendor.accountNumber} onChange={handleNewVendorChange} />
              <input type="number" name="discount" placeholder="Discount %" value={newVendor.discount} onChange={handleNewVendorChange} />
              <input type="text" name="contact" placeholder="Contact" value={newVendor.contact} onChange={handleNewVendorChange} />
              <input type="text" name="notes" placeholder="Notes" value={newVendor.notes} onChange={handleNewVendorChange} />
              <div>
                <h3>Categories</h3>
                {categories.map(category => (
                  <label key={category}>
                    <input
                      type="checkbox"
                      value={category}
                      checked={newVendor.categories.includes(category)}
                      onChange={handleNewVendorCategoryChange}
                    />
                    {category}
                  </label>
                ))}
              </div>
              <input type="text" name="url" placeholder="URL" value={newVendor.url} onChange={handleNewVendorChange} />
              <input type="text" name="address" placeholder="Address" value={newVendor.address} onChange={handleNewVendorChange} />

              <button onClick={addVendor}>Add Vendor</button>
            </div>
          )}
        </>
      ) : (
        <SignIn onSignIn={setIsAuthenticated} />
      )}
    </div>
  );
}

export default App;
