import React, { useState } from 'react';
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from './firebase'; // Make sure the path is correct based on your project structure

const categories = [
  'Furniture', 'Lighting', 'Flooring', 'Wall Art', 'Window Treatments', 'Rugs', 
  'Bedding', 'Kitchenware', 'Outdoor Furniture', 'Appliances', 'Storage Solutions', 
  'Accessories', 'Paint', 'Upholstery', 'Fixtures', 'Décor', 'Smart Home Technology', 
  'Custom Cabinetry', 'Wallpaper', 'Fabric', 'Leather', 'Plants', 'Handymen', 'Hardware', 'Cleaning', 'Tailors'
];

const VendorList = ({ vendors, onEdit, onDelete }) => {
  const [editingId, setEditingId] = useState(null);
  const [editData, setEditData] = useState({});
  const [selectedVendor, setSelectedVendor] = useState(null);

  const handleEditClick = (vendor) => {
    setEditingId(vendor.id);
    setEditData({ ...vendor });
  };

  const handleSaveClick = async (e) => {
    e.stopPropagation();
    const vendorRef = doc(db, "vendors", editData.id);
    await updateDoc(vendorRef, editData);
    onEdit(editData);
    setEditingId(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    const updatedCategories = checked
      ? [...editData.categories, value]
      : editData.categories.filter(category => category !== value);
    setEditData({ ...editData, categories: updatedCategories });
  };

  const handleDeleteClick = async (id) => {
    const vendorRef = doc(db, "vendors", id);
    await deleteDoc(vendorRef);
    onDelete(id);
  };

  return (
    <div>
      <table className="vendor-table">
        <thead>
          <tr>
            <th>VENDOR NAME</th>
            <th>EMAIL</th>
            <th>USER ID</th>
            <th>PASSWORD</th>
            <th>ACCOUNT NUMBER</th>
            <th>DISCOUNT %</th>
            <th>CONTACT</th>
            <th>NOTES</th>
            <th>CATEGORIES</th>
            <th>ADDRESS</th>
            <th>URL</th>
            
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {vendors.map((vendor) => (
            <tr key={vendor.id} onClick={() => editingId === null && setSelectedVendor(vendor)}>
              {editingId === vendor.id ? (
                <>
                  <td><input type="text" name="name" value={editData.name} onChange={handleChange} required /></td>
                  <td><input type="email" name="email" value={editData.email} onChange={handleChange} /></td>
                  <td><input type="text" name="userId" value={editData.userId} onChange={handleChange} /></td>
                  <td><input type="text" name="password" value={editData.password} onChange={handleChange} /></td>
                  <td><input type="text" name="accountNumber" value={editData.accountNumber} onChange={handleChange} /></td>
                  <td><input type="number" name="discount" value={editData.discount} onChange={handleChange} /></td>
                  <td><input type="text" name="contact" value={editData.contact} onChange={handleChange} /></td>
                  <td><input type="text" name="notes" value={editData.notes} onChange={handleChange} /></td>
                  <td>
                    {categories.map(category => (
                      <label key={category}>
                        <input
                          type="checkbox"
                          value={category}
                          checked={editData.categories.includes(category)}
                          onChange={handleCategoryChange}
                        />
                        {category}
                      </label>
                    ))}
                  </td>
                  <td><input type="text" name="address" value={editData.url} onChange={handleChange} /></td>
                  <td><input type="text" name="url" value={editData.url} onChange={handleChange} /></td>
                  <td>
                    <button onClick={handleSaveClick}>Save</button>
                    <button onClick={(e) => { e.stopPropagation(); setEditingId(null); }}>Cancel</button>
                  </td>
                </>
              ) : (
                <>
                  <td>{vendor.name}</td>
                  <td>{vendor.email}</td>
                  <td>{vendor.userId}</td>
                  <td>{vendor.password}</td>
                  <td>{vendor.accountNumber}</td>
                  <td>{vendor.discount}%</td>
                  <td>{vendor.contact}</td>
                  <td>{vendor.notes}</td>
                  <td>{vendor.categories.join(', ')}</td>
                  <td>{vendor.address}</td>
                  <td><a href={vendor.url} target="_blank" rel="noopener noreferrer">{vendor.url}</a></td>
                  <td>
                    <button onClick={(e) => { e.stopPropagation(); handleEditClick(vendor); }}>Edit</button>
                    <button onClick={(e) => { e.stopPropagation(); handleDeleteClick(vendor.id); }}>Delete</button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {selectedVendor && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setSelectedVendor(null)}>&times;</span>
            <h2>{selectedVendor.name}</h2>
            <p>Email: {selectedVendor.email}</p>
            <p>User ID: {selectedVendor.userId}</p>
            <p>Password: {selectedVendor.password}</p>
            <p>Account Number: {selectedVendor.accountNumber}</p>
            <p>Discount: {selectedVendor.discount}%</p>
            <p>Contact: {selectedVendor.contact}</p>
            <p>Notes: {selectedVendor.notes}</p>
            <p>Categories: {selectedVendor.categories.join(', ')}</p>
            <p>Address: {selectedVendor.address}</p>
            <p>URL: <a href={selectedVendor.url} target="_blank" rel="noopener noreferrer">{selectedVendor.url}</a></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorList;
