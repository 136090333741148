// SignIn.js
import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase'; // Import firebaseConfig if it's exported

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function SignIn({ onSignIn }) {
  const [inputPassword, setInputPassword] = useState('');
  const [storedPassword, setStoredPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPassword = async () => {
      try {
        const docRef = doc(db, "auth", "login");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setStoredPassword(docSnap.data().password);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching password:", error);
      }
    };

    fetchPassword();
  }, []);

  const handleSignIn = () => {
    if (inputPassword === storedPassword) {
      onSignIn(true);
    } else {
      setError('Incorrect password.');
    }
  };

  return (
    <div className="sign-in">
      <h2>Sign In</h2>
      <input
        type="password"
        value={inputPassword}
        onChange={(e) => setInputPassword(e.target.value)}
        placeholder="Enter password"
      />
      <button onClick={handleSignIn}>Sign In</button>
      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default SignIn;
