import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDuIKYYXg9uWKshORrnF4rpPKxnSQTmnS4",
    authDomain: "stripedstreet-dd738.firebaseapp.com",
    projectId: "stripedstreet-dd738",
    storageBucket: "stripedstreet-dd738.appspot.com",
    messagingSenderId: "923687186312",
    appId: "1:923687186312:web:09dc5412411f6d9107543d"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const db = getFirestore(app);

export { auth, db, firebaseConfig};
